import { render, staticRenderFns } from "./LoyaltyImage.vue?vue&type=template&id=31162254&scoped=true"
import script from "./LoyaltyImage.vue?vue&type=script&lang=js"
export * from "./LoyaltyImage.vue?vue&type=script&lang=js"
import style0 from "./LoyaltyImage.vue?vue&type=style&index=0&id=31162254&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31162254",
  null
  
)

/* custom blocks */
import block0 from "@dundle/locale/data/loyalty.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Floyalty%2FLoyaltyImage.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports