<template>
    <AdminContentText v-if="!preview && cmsIsEnabled" :content="content" :params="params" />
    <component :is="tag" v-else @click="onClick" v-html="processedContent"></component>
</template>
<script>
import { mapGetters } from 'vuex';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        AdminContentText: () => import(/* webpackChunkName: 'Admin' */ '~/components/admin/AdminContentText'),
    },
    mixins: [TranslationMixin],
    props: {
        tag: {
            type: String,
            default: 'div',
        },

        content: {
            required: false,
            default() {
                return {
                    value: '',
                };
            },
        },

        preview: {
            default: false,
        },

        params: {
            type: Object,
        },
    },
    data() {
        return {
            hrefRegex: /href="(\/.*?)"/g,
            doubleBraceRegex: /{{([a-zA-Z0-9]+)}}/g,
            singelBraceRegex: /{([a-zA-Z0-9]+)}/g,
        };
    },
    computed: {
        ...mapGetters({
            cmsIsEnabled: 'cms/isEnabled',
        }),

        type() {
            return 'html';
        },

        processedContent() {
            const content = this.content.value.replace(this.hrefRegex, (match, href) => {
                const fullPath = `/${this.$locale.region}${href}`;

                const { route } = this.$router.resolve(fullPath);

                if (typeof route.name === 'string') {
                    const reversedRoute = this.$locale.reverseRoute({
                        ...route,
                        deriveRegion: true,
                    });

                    if (typeof reversedRoute === 'undefined') {
                        return `href="${fullPath}"`;
                    }

                    const path = this.$locale.path(
                        {
                            ...reversedRoute,
                            trailingSlash: reversedRoute.name === 'brand',
                        },
                        this.$locale.iso
                    );

                    return `href="${path}"`;
                } else {
                    console.error('Can not find route for ' + fullPath);
                    return `href="${fullPath}"`;
                }
            });

            return this.translateParams(content, this.params);
        },
    },

    methods: {
        onClick(ev) {
            if (
                ev.target &&
                ev.target.tagName.toLowerCase() === 'a' &&
                new URL(ev.target.href).host.split(':')[0] === this.$locale.domain &&
                !ev.target.href.includes('/magazine/')
            ) {
                this.$router.push(ev.target.pathname);

                ev.preventDefault();
                ev.stopPropagation();
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep h3 {
    font-size: 1rem;
    margin-top: 2.3rem;
    color: $color-primary-dark;
    font-weight: 700;
}

::v-deep strong {
    font-weight: bold;
}

::v-deep .inline-list-item {
    p {
        margin: 0;
    }
}
::v-deep li p {
    margin-bottom: 0.35rem;
}

::v-deep .alert-warning {
    color: $color-warning-text;
    background-color: $color-warning-bg;
    border-color: $color-warning-border;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

::v-deep .quote-block {
    color: $color-text-bold;
    font-size: 1.3rem;
    font-style: italic;
    padding: 0.75rem 1.25rem;
    text-align: center;
    quotes: '" ' ' "';
    p::before {
        content: open-quote;
    }
    p::after {
        content: close-quote;
    }
}

::v-deep .ProseMirror {
    &:focus {
        outline: none;
    }
}
</style>
